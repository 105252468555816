<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout" :page-sizes="pageSizes" :total="total" v-bind="$attrs" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: { // 总条数
      required: true,
      type: Number,
    },
    page: { // 页码
      type: Number,
      default: 1,
    },
    limit: { // 分页条数
      type: Number,
      default: 20,
    },
    pageSizes: { // 分页条数
      type: Array,
      default() {
        return [10, 20, 30, 50]
      },
    },
    layout: { // 分页展示模块
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    background: { // 背景
      type: Boolean,
      default: true,
    },
    hidden: { // 是否隐藏
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      },
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      },
    },
  },
  methods: {
    // 改变每页条数
    handleSizeChange(val) {
      this.$emit('pagination', { page: 1, limit: val });
    },
    // 改变页码
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize });
    },
  },
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 12px;
  border-top: none;
  text-align: center;
}
.pagination-container.hidden {
  display: none;
}
</style>
