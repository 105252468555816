<template>
  <div class="page page-box">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="学员：">
          <el-input v-model="listQuery.Name" placeholder="学员姓名" class="input-width" />
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <div class="scatter">
        <div></div>
        <div>
          <el-button @click="batchSecede()" class="plain-button">批量退班</el-button>
          <el-button @click="batchTransfer()" class="plain-button">批量调班</el-button>
        </div>
      </div>
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="Name" label="姓名" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" class="text-botton" @click="readDetail(row)">{{ row.Name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="性别" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.Sex | studentSex }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Birthday" label="出生年月" align="center" min-width="100" />
        <el-table-column prop="CourseNumber" label="已上课时" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="Transfer(row)"> 调班 </el-button>
            <el-button type="text" @click="delClubStudent(row)"> 退班 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer class="footer-box">
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubStudentList" />
      </footer>
    </main>
    <!-- 调班 -->
    <el-dialog :title="clubClassTitle" :visible.sync="clubClassVisible" width="30%" :close-on-click-modal='false'>
      <el-form :model="clubClassForm" :rules="rules" ref="clubClassForm" label-width="100px">
        <el-form-item label="班级修改:" prop="Class">
          <el-select class="select-box" @change='changeClassStatus' filterable v-model="clubClassForm.ClassIds" placeholder="请选择">
            <el-option v-for="item in classList" :key="item.Id" :label="item.Name" :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clubClassVisible = false" class="plain-button">取 消</el-button>
        <el-button @click="updateClubStudent()" class="discoloration"> 确 定 </el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog title="查看详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>姓名：</span><span>{{ readStudentForm.Name }}</span>
        </el-col>
        <el-col :span="8">
          <span>性别：</span><span>{{ readStudentForm.Sex|studentSex }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>出生年月：</span><span>{{ readStudentForm.Birthday }}</span>
        </el-col>
        <!-- <el-col :span="12">
          <span>已上课时：</span><span>{{ readStudentForm.CourseNumber }}</span>
        </el-col> -->
        <el-col :span="12">
          <span>学员身份证号：</span><span>{{ readStudentForm.IdCard }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span>所属班级：</span><span>{{  Array.isArray(readStudentForm.Classes) && readStudentForm.Classes.map(item=> item.Name).join('/')  }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span>已绑定家长及手机号：</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span v-for="(item,index) in readStudentForm.Elders" :key='index'>
            <div class="line-feed">
              {{index+1}}. {{ item.Type | elderType }}({{ item.Type !== 7 ? item.Name : '' }} {{ item.Phone }})
            </div>
          </span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getClubStudentList, batchQuitOrMoveClass, getAllClubClass } from "@/service/educational";
import pagination from '@/components/pagination/pagination.vue'; // 引入分页
import { deepClone } from '@/utils/index'; // 引入深拷贝

const ListQuery = {  // 查询数据
  PageNo: 1,    // 页码
  PageSize: 50, // 分页条数
  Name: '',     // 学员名称筛选
};
export default {
  name: "Student",
  components: {
    pagination,
  },
  data() {
    const validateClass = (rule, value, callback) => {
      if (this.clubClassForm.ClassIds) {
        callback();
      }
      return callback(new Error('请选择所属班级'));
    };
    return {
      listQuery: deepClone(ListQuery),
      tableList: [],   // 表格数据
      total: 0,        // 表格总条数
      loading: false,  // 加载状态
      multipleSelection: [],  // 多选数据
      clubClassTitle: '调班', // 调班窗口初始标题
      clubClassForm: {}, // 调班数据
      clubClassVisible: false, // 调班弹窗初始状态
      rules: {
        Class: [{ required: true, validator: validateClass, trigger: 'blur' }],
      },
      classList: [], // 所属班级数据
      readVisible: false, // 详情弹窗
      readStudentForm: {},
    }
  },
  created() {
    this.getClubStudentList();
    this.getAllClubClass();
  },
  methods: {
    /**
      * 获取学员数据
      */
    async getClubStudentList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubStudentList({ ...this.listQuery, ClassId: this.$route.query.id });
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
      * 获取所有班级列表
      */
    async getAllClubClass() {
      this.classList = await getAllClubClass();
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.getClubStudentList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClubStudentList();
    },

    /**
     * 页头-批量退班
     */
    async batchSecede() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 0) {
        this.$message.error('请勾选至少一条数据');
        return;
      }
      try {
        let res = await this.$confirm('是否确定为学员退班？', '退班', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await batchQuitOrMoveClass({
          StudentIds: this.multipleSelection.map(item => item.Id),
          OldClassId: this.$route.query.id
        });
        this.getClubStudentList();
        this.$message({
          message: '批量退班成功',
          type: 'success'
        });
      } catch (err) { };
    },

    /**
     * 页头-批量调班
     */
    async batchTransfer() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 0) {
        this.$message.error('请勾选至少一条数据');
        return;
      }
      this.clubClassVisible = true;
      this.clubClassForm.ClassIds = this.$route.query.id;
      this.clubClassTitle = '批量调班';
    },

    /**
     * 操作-调班
     */
    Transfer(row) {
      this.clubClassVisible = true;
      this.clubClassForm = { ...row };
      this.clubClassTitle = '调班';
      this.clubClassForm.ClassIds = this.$route.query.id;
    },

    /**
     * 确认调班
     */
    updateClubStudent() {
      this.$refs.clubClassForm.validate(async (valid) => {
        if (!valid) return;

        // 选择当前班级时提示
        if (this.clubClassForm.ClassIds === this.$route.query.id) {
          this.$message.error('不能调班到当前班级');
          return;
        }

        if (this.clubClassTitle === '调班') {
          await batchQuitOrMoveClass({
            NewClassId: this.clubClassForm.ClassIds,
            OldClassId: this.$route.query.id,
            StudentIds: [this.clubClassForm.Id]
          });
          this.$message.success('调班成功');
        } else {
          await batchQuitOrMoveClass({
            NewClassId: this.clubClassForm.ClassIds,
            OldClassId: this.$route.query.id,
            StudentIds: this.multipleSelection.map(item => item.Id),
          });
          this.$message.success('批量调班成功');
        }
        this.clubClassVisible = false;
        this.getClubStudentList();
      })
    },

    /**
     * 操作-退班
     */
    async delClubStudent(row) {
      try {
        let res = await this.$confirm('是否确定为学员退班？', '退班', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await batchQuitOrMoveClass({ StudentIds: [row.Id], OldClassId: this.$route.query.id });
        this.$message.success('退班成功');
        this.getClubStudentList();
      } catch (err) { };
    },

    /**
     * 主体-table表格多选框操作
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /**
     * 选择所属班级时改变验证状态
     */
    changeClassStatus() {
      this.$refs.clubClassForm.validateField('Class');
      this.$forceUpdate();
    },

    readDetail(row) {
      this.readVisible = true;
      this.readStudentForm = { ...row }
    }
  },
}
</script>
<style lang='scss' scoped>
.page-box {
  overflow: hidden;
}
.page-main {
  height: 100%;
}
.page-header {
  margin-top: 0;
}
.text-botton {
  cursor: pointer;
}
.scatter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 42px !important;
}
</style>