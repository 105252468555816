<template>
  <div class="page page-box">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="选择日期：">
          <div class="block">
            <el-date-picker v-model="dateValue" type="daterange" align="right" :clearable="false" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :picker-options="pickerOptions" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe @sort-change="changeTableSort">
        <el-table-column prop="StartTime" label="课程日期" align="center" min-width="100">
          <template slot-scope="{ row }">
            {{ row.StartTime && moment(row.StartTime*1000).format('YYYY-MM-DD') || '' }}
          </template>
        </el-table-column>
        <el-table-column prop="StartTime" label="课程时间" align="center" sortable min-width="100">
          <template slot-scope="{ row }">
            {{ (row.StartTime && moment(row.StartTime*1000).format('HH:mm'))+'-'+(row.EndTime&&moment(row.EndTime*1000).format('HH:mm')) || '' }}
          </template>
        </el-table-column>
        <el-table-column prop="Schedule" label="班级课时" align="center" width="80px">
          <template slot-scope="{ row }">
            第{{row.Schedule}}课时
          </template>
        </el-table-column>
        <el-table-column prop="SiteName" label="训练场地" align="center" min-width="160" />
        <el-table-column prop="Clothings" label="训练服装" align="center">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.Clothings) && row.Clothings.map(item=> item.Name).join('/') }}
          </template>
        </el-table-column>
        <el-table-column prop="Theme" label="训练主题" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="操作" align="center" width="80px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="readDetail(row)"> 详情 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer class="footer-box">
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubCourseList" />
      </footer>
    </main>
    <!-- 查看详情 -->
    <el-dialog title="查看详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <div class="class-content" v-loading='loading'>
        <el-row class="margin-row">
          <el-col :span="12">
            <span>课程日期：</span>
            <span>
              {{ readRecordForm.StartTime && moment(readRecordForm.StartTime*1000).format('YYYY-MM-DD ') || '' }}
            </span>
          </el-col>
          <el-col :span="11" :offset="1">
            <span>课程时间：</span><span>
              {{ (readRecordForm.StartTime && moment(readRecordForm.StartTime*1000).format('HH:mm'))+'至'+(readRecordForm.EndTime && moment(readRecordForm.EndTime*1000).format('HH:mm')) || '' }}
            </span>
          </el-col>
        </el-row>
        <el-row class="margin-row">
          <el-col :span="12">
            <span>课程主题：</span><span>{{ readRecordForm.Theme }} </span>
          </el-col>
          <el-col :span="11" :offset="1">
            <span>训练时长：</span><span>{{Duration}}分钟</span>
          </el-col>
        </el-row>
        <el-row class="margin-row">
          <el-col :span="12">
            <span>班级课时：</span><span> 第{{ readRecordForm.Schedule }}课时 </span>
          </el-col>
          <el-col v-if="readRecordForm.Status===3" :span="11" :offset="1">
            <span>课程状态：</span><span>{{readRecordForm.Status|courseStatus}}</span>
          </el-col>
        </el-row>
        <el-row class="margin-row">
          <el-col>
            <h4>课程内容</h4>
          </el-col>
        </el-row>
        <el-row class="margin-row">
          <el-col>
            <template v-for="(item,index) in TrainMods">
              <div :key="index" class="padding-div">
                <div>{{`${index+1+'、'} ${item.Name} (${item.Duration}分钟)`}}</div>
                <div>{{`训练内容：`}}</div>
                <div class="indent" style="white-space: pre-wrap;">{{item.Remarks}}</div>
              </div>
            </template>
          </el-col>
        </el-row>
        <el-row class="margin-row">
          <el-col>
            <h4>上课要求</h4>
          </el-col>
        </el-row>
        <el-row class="site-name margin-row">
          <el-col :span="4"><span>课程场地：</span></el-col>
          <el-col :span="6" class="site-name">
            <span>{{ readRecordForm.SiteName }}</span>
          </el-col>
          <el-col :span="4">
            <span>课程服装：</span>
          </el-col>
          <el-col :span="10" class="clothing-box">
            <template v-for="(item,index) in readRecordForm.Clothings">
              <div class="radio-box" :key="index">
                <span>{{`${item.Name}(${item.Color})`}}</span>
                <el-image class="image-box" :src="`${cosHttp}${item.ImageAddress}`" :preview-src-list="[`${cosHttp}${item.ImageAddress}`]" />
              </div>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClubCourseList } from "@/service/educational";
import pagination from '@/components/pagination/pagination.vue'; // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝
import moment from 'moment';

const ListQuery = { // 查询数据
  PageNo: 1,  // 页码
  PageSize: 50, // 分页条数
};
export default {
  name: "Examine",
  components: {
    pagination,
  },
  data() {
    return {
      moment,
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      readVisible: false,  // 查看详情弹窗状态
      readRecordForm: {},
      pickerOptions: {
        disabledDate(time) { //时间禁用
          return time.getTime() > Date.now();
        },
      },
      cosHttp: process.env.VUE_APP_COSHTTP,
      TrainMods: [], // 训练模块
      dateValue: [new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000), new Date()],
      sortingType: undefined,
    }
  },
  created() {
    this.getClubCourseList();
  },
  computed: {
    Duration() {
      let Duration = 0;
      Array.isArray(this.TrainMods) && this.TrainMods.forEach(item => {
        Duration += Number(item.Duration);
      })
      return Duration;
    }
  },
  methods: {

    /**
      * 获取课程列表数据
      */
    async getClubCourseList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubCourseList({
        ...this.listQuery,
        StartTime: Array.isArray(this.dateValue) && this.dateValue.length > 0 && parseInt(new Date(moment(this.dateValue[0]).format('YYYY-MM-DD 00:00:00')).getTime() / 1000),
        EndTime: Array.isArray(this.dateValue) && this.dateValue.length > 0 && parseInt(new Date(moment(this.dateValue[1]).format('YYYY-MM-DD 23:59:59')).getTime() / 1000),
        Status: 3,
        ClassId: this.$route.query.id,
        SortProperty: this.sortingType ? "start_time" : undefined,
        Sequencer: this.sortingType || undefined
      });
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.StartTime = Array.isArray(this.dateValue) && this.dateValue.length > 0 && moment(this.dateValue[0]).format('YYYY-MM-DD') || undefined;
      this.listQuery.EndTime = Array.isArray(this.dateValue) && this.dateValue.length > 0 && moment(this.dateValue[1]).format('YYYY-MM-DD') || undefined;
      this.getClubCourseList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.dateValue = [new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000), new Date()];
      this.getClubCourseList();
    },

    /**
     * 操作-查看
     */
    readDetail(row) {
      this.readVisible = true;
      this.TrainMods = row.TrainMods;
      this.readRecordForm = { ...row };
    },

    //选择指定列进行排序
    changeTableSort(column) {

      //获取字段名称和排序类型
      let sortingType = column.order;

      //按照降序排序
      if (sortingType == "descending") {
        this.sortingType = 'desc';
      } else if (sortingType == "ascending") {
        this.sortingType = 'asc';
      }
      //按照升序排序
      else {
        this.sortingType = undefined;
      }
      // 判断是降序升序还是按原来排序，请求接口，使用接口排序
      this.getClubCourseList();

    }
  },
}
</script>
<style lang='scss' scoped>
.page-box {
  overflow: hidden;
}

.site-name {
  display: flex;
  align-items: center;
}
.clothing-box {
  display: flex;
  align-items: center;
}
.radio-box {
  display: flex;
  align-items: center;
  span {
    margin-right: 24px;
  }
}
.class-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  font-weight: 400;
  color: #6a757e;
  font-size: 14px;
  h4 {
    margin: 10px 0;
    font-weight: 500;
    color: #21293a;
  }
}
.page-header {
  margin-top: 0;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 42px !important;
}
</style>