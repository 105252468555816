import http from "@/utils/http";

/**
 * 获取校区树
 */
export const getOrgTree = data => {
	return http.get("/club/getOrgTree", data);
};

/**
 * 新增编辑校区树
 */
export const updateOrg = data => {
	return http.post("/club/updateOrg", data);
};

/**
 * 删除校区树
 */
export const delOrg = data => {
	return http.post("/club/delOrg", data);
};

/**
 * 班级列表
 */
export const getClubClassList = data => {
	return http.get("/club/getClubClassList", data);
};

/**
 * 新增编辑班级
 */
export const updateClubClass = data => {
	return http.post("/club/updateClubClass", data);
};

/**
 * 根据id获取班级详情
 */
export const getClubClassById = data => {
	return http.get("/club/getClubClassById", data);
};

/**
 * 删除班级
 */
export const delClubClass = data => {
	return http.post("/club/delClubClass", data);
};

/**
 * 获取入班审核列表
 */
export const getClassCheckList = data => {
	return http.post("/club/getClassCheckList", data);
};

/**
 * 批量审核
 */
export const batchClassApplyCheck = data => {
	return http.post("/club/batchClassApplyCheck", data);
};

/**
 * 入班审核
 */
export const classApplyCheck = data => {
	return http.post("/club/classApplyCheck", data);
};

/**
 * 获取场地列表
 */
export const getClubSiteList = data => {
	return http.get("/club/getClubSiteList", data);
};

/**
 * 获取新增编辑场地
 */
export const updateClubSite = data => {
	return http.post("/club/updateClubSite", data);
};

/**
 * 根据id获取场地详情
 */
export const getClubSiteById = data => {
	return http.get("/club/getClubSiteById", data);
};

/**
 * 删除场地
 */
export const delClubSite = data => {
	return http.post("/club/delClubSite", data);
};

/**
 * 获取服装列表
 */
export const getClubClothingList = data => {
	return http.get("/club/getClubClothingList", data);
};

/**
 * 获取新增编辑服装
 */
export function addOrUpdateClubClothing(data) {
	return http.post("/club/addOrUpdateClubClothing", data);
}

/**
 * 根据id获取服装详情
 */
export const getClubClothingById = data => {
	return http.get("/club/getClubClothingById", data);
};

/**
 * 删除服装
 */
export const delClubClothing = data => {
	return http.post("/club/delClubClothing", data);
};

/**
 * 获取教练列表分页
 */
export const getClubTeacherList = data => {
	return http.get("/club/getClubTeacherList", data);
};

/**
 * 获取所有教练列表
 */
export const getAllClubTeacher = data => {
	return http.get("/club/getAllClubTeacher", data);
};

/**
 * 获取新增编辑教练
 */
export const updateClubTeacher = data => {
	return http.post("/club/updateClubTeacher", data);
};

/**
 * 批量审核教练
 */
export const checkTeacher = data => {
	return http.post("/club/checkTeacher", data);
};

/**
 * 根据id获取教练详情
 */
export const getClubTeacherById = data => {
	return http.get("/club/getClubTeacherById", data);
};

/**
 * 删除教练
 */
export const delClubTeacher = data => {
	return http.post("/club/delClubTeacher", data);
};

/**
 * 获取展示课程列表
 */
export function getClubShowCourseList(data) {
	return http.get("/club/getClubShowCourseList", data);
}

/**
 * 获取新增编辑课程
 */
export function addOrUpdateClubShowCourse(data) {
	return http.post("/club/addOrUpdateClubShowCourse", data);
}

/**
 * 根据id获取课程详情
 */
export const getClubShowCourseById = data => {
	return http.get("/club/getClubShowCourseById", data);
};

/**
 * 删除课程
 */
export function delClubShowCourse(data) {
	return http.post("/club/delClubShowCourse", data);
}