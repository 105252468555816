<template>
  <div class="page-box page-padding">
    <div class="go-class">
      <el-button @click="()=> $router.go(-1)" class="plain-button">
        <div class="img-center"> <img src="@/assets/return.png" alt="返回" class="add-img"> <span>返回</span></div>
      </el-button>
    </div>
    <div class="contentBox">
      <div class="class-content" v-loading='loading'>
        <h2><span>{{ classDetailData && classDetailData.Name }}</span>
          <span v-if="classDetailData.Status===1" class="status-ing">{{ classDetailData.Status | classStatus }}</span>
          <span v-if="classDetailData.Status===2" class="status-ed">{{ classDetailData.Status | classStatus }}</span>
          <span v-if="classDetailData.Status===3" class="status-will">{{ classDetailData.Status | classStatus }}</span>
        </h2>
        <div class="content-box">
          <div class="content-left">
            <div><span>班级编码：</span><span>{{ classDetailData.Code }}</span></div>
            <div><span>班级类型：</span><span>{{ classDetailData.Type | classType }}</span></div>
            <div>
              <span>班级教练：</span>
              <span v-if='Array.isArray(classDetailData.Teachers) && classDetailData.Teachers.length > 0'>{{ Array.isArray(classDetailData.Teachers) && classDetailData.Teachers.map(item=> `${item.Name}(${item.TeacherType === 1 ? '主教练' : '助教'}${item.Manager ? '/管理员' : ''})`).join('/') }}</span>
              <span v-else></span>
            </div>
            <div><span>班级人数：</span><span>{{ classDetailData.MemberCount }}</span></div>
            <div v-show="classDetailData.Type === 2"><span>开始时间：</span><span>{{ classDetailData.StartDate | dateFormat }}</span></div>
            <div v-show="classDetailData.Type === 2"><span>结束时间：</span><span>{{ classDetailData.EndDate | dateFormat }}</span></div>
            <div><span>班级课时：</span>
              <span v-if="classDetailData.Schedule>0">第{{classDetailData.Schedule}}课时</span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <span>邀请家长入班</span>
        <el-image v-if="classDetailData && classDetailData.QRElderCode" :src="`${classDetailData && classDetailData.QRElderCode}`" :preview-src-list="[`${classDetailData.QRElderCode}`]" class="QR-img">
        </el-image>
        <el-button type="primary" @click="getQrCode()" class="cope-link">二维码下载</el-button>
      </div>
    </div>
    <el-tabs class="tabs-box" v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="入班审核" name="examine">
        <examine @getDetail='getClubClassById' v-if="activeName==='examine'" />
      </el-tab-pane>
      <el-tab-pane label="学员列表" name="student">
        <student v-if="activeName==='student'" />
      </el-tab-pane>
      <el-tab-pane label="上课记录" name="record">
        <record v-if="activeName==='record'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import examine from '@/views/organizational/classComponent/examine.vue';
import student from '@/views/organizational/classComponent/student.vue';
import record from '@/views/organizational/classComponent/record.vue';
import { getClubClassById } from "@/service/organizational.js";
import { mapState } from "vuex";
import vueQr from "vue-qr";


export default {
  name: 'ClassDetail',

  components: {
    examine,
    student,
    record,
    vueQr
  },

  data() {
    return {
      Id: "",
      classDetailData: {},   // 班级详情数据
      activeName: 'examine', // tab选中标识
      loading: false,
      testUrl: "https://api.qx.flutterbbs.cn",
    }
  },

  computed: {
    ...mapState(['userInfo'])
  },

  created() {

  },

  async mounted() {
    this.Id = this.$route.query.id;
    await this.getClubClassById();
  },

  methods: {
    /**
     * 获取详情数据
     */
    async getClubClassById() {
      this.loading = true;
      this.classDetailData = await getClubClassById({ Id: this.Id });
      this.loading = false;
    },

    /**
     * 改变tab选中
     */
    handleClickTab() {
    },

    getQrCode() {
      const iconUrl = this.classDetailData.QRElderCode;
      const htmlElement = document.createElement('a');
      const event = new MouseEvent('click');
      htmlElement.download = `${this.userInfo.OrgName}-${this.classDetailData.Name}`;
      htmlElement.href = iconUrl;
      htmlElement.dispatchEvent(event);
    },
  }
}
</script>

<style lang='scss' scoped>
h2 {
  background: #ffffff;
  padding-bottom: 16px;
  padding-left: 32px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #21293a;
}
.content-box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  // height: 100px;
  padding: 16px 0 12px 32px;
  box-sizing: border-box;
  font-weight: 400;
  color: #6a757e;
  font-size: 14px;
  .content-left {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    div {
      width: 25%;
      display: flex;
      overflow: hidden;
      margin-bottom: 8px;
      span {
        min-width: 80px;
        padding: 2px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    div:nth-child(3n) {
      width: 50%;
      margin-bottom: 0;
    }
  }
}
.page {
  display: flex;
  flex-direction: column;
}
.class-content {
  display: flex;
  flex-direction: column;
}
.tabs-box {
  // min-height: 180px;
  display: flex;
  flex-direction: column;
  // /deep/.el-tabs__content {
  //   flex: 1;
  //   // height: 0;
  //   .el-tab-pane {
  //     height: 100%;
  //   }
  // }
}

/deep/.el-tabs__header {
  padding-left: 32px !important;
}

.contentBox {
  padding-right: 100px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  .content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    padding-top: 16px;
    cursor: pointer;
    span {
      font-size: 14px;
      margin-bottom: 8px;
      cursor: text;
      font-weight: 400;
      color: #6a757e;
    }
    .cope-link {
      margin-top: 8px;
      color: #8fcc80;
      cursor: pointer;
    }
    .QR-img {
      width: 100px;
      height: 100px;
    }
  }
}
.go-class {
  margin-bottom: 16px;
}
</style>