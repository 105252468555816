<template>
  <div class="page page-box">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="学员：">
          <el-input v-model="listQuery.StudentName" placeholder="学员姓名" class="input-width" />
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="listQuery.ElderPhone" placeholder="家长手机号" class="input-width" />
        </el-form-item>
        <el-form-item label="状态：">
          <el-select class="input-box" v-model="listQuery.Status" clearable placeholder="请选择">
            <el-option v-for="item in ClassAuditStatusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button">重置</el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <div class="scatter" v-if="Array.isArray(Teachers)&&Teachers.length!==0">
        <div></div>
        <el-button @click="approveAudit()">审核同意</el-button>
      </div>
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" ref="multipleTable" @selection-change="handleSelectionChange" border fit stripe>
        <el-table-column type="selection" width="55" :selectable="
          (row) => {
            return row.Status === 2
          }
        " align="center" />
        <el-table-column prop="StudentName" label="姓名" align="center" />
        <el-table-column prop="StudentBirthday" label="学员生日" align="center" min-width="100">
          <template slot-scope="{ row }">
            {{ row.StudentBirthday&&moment(row.StudentBirthday*1000).format('YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column prop="StudentIdCard" label="学员身份证号" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="ElderType" label="家长身份" align="center" width="80px">
          <template slot-scope="{ row }">
            <span>{{ row.ElderType | elderType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ElderName" label="家长姓名" align="center" />
        <el-table-column prop="ElderPhone" label="家长手机号" align="center" width="120px" />
        <el-table-column label="状态" align="center" width="80px">
          <template slot-scope="{ row }">
            <span v-if="row.Status===1" class="statusBox"><span class="consentStatus"></span>{{ row.Status | ClassAuditStatus }}</span>
            <span v-if="row.Status===2" class="statusBox"><span class="awaitStatus"></span>{{ row.Status | ClassAuditStatus }}</span>
            <span v-if="row.Status===3" class="statusBox"><span class="refuseStatus"></span>{{ row.Status | ClassAuditStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="{ row }">
            <el-button type="text" v-if="row.Status === 1" @click="readDetail(row)"> 详情 </el-button>
            <!-- <el-button type="text" v-if="row.Status === 1" @click="deleteAudit(row)"> 删除 </el-button> -->
            <el-button type="text" v-if="row.Status === 3" @click="readDetail(row)"> 详情 </el-button>
            <el-button type="text" v-if="row.Status === 2" @click="changeAuditVisible(row)"> 审核 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer class="footer-box">
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClassCheckList()" />
      </footer>
      <div v-if="Array.isArray(Teachers)&&Teachers.length===0" class="assignCoach-div">
        <p>当前班级没有教练，请先为班级分配教练</p>
        <el-button @click="assignCoach()" class="discoloration" type="primary"> 分配教练 </el-button>
      </div>
    </main>
    <!-- 编辑 -->
    <el-dialog :title="ClassAuditTitle" :visible.sync="ClassAuditVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="ClassAuditForm" ref='ClassAuditForm' label-width="100px" :rules="rules">
        <el-form-item label="家长姓名:" prop="ElderName">
          <el-input v-model="ClassAuditForm.ElderName" />
        </el-form-item>
        <el-form-item label="家长身份:" prop="ElderType">
          <el-select class="select-box" v-model="ClassAuditForm.ElderType">
            <el-option v-for="item in parentTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号:" prop="ElderPhone">
          <el-input v-model="ClassAuditForm.ElderPhone" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ClassAuditVisible = false" class="plain-button">取 消</el-button>
        <el-button @click="updateClassAudit()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog title="查看详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>学员姓名：</span><span>{{ readClassAuditForm.StudentName }}</span>
        </el-col>
        <el-col :span="8">
          <span>学员生日：</span><span>{{ readClassAuditForm.StudentBirthday&&moment(readClassAuditForm.StudentBirthday*1000).format('YYYY-MM-DD') }}</span>
        </el-col>
        <el-col :span="4">
          <span v-if="readClassAuditForm.Status===1" class="status-ing">{{ readClassAuditForm.Status | ClassAuditStatus }}</span>
          <span v-if="readClassAuditForm.Status===2" class="status-will">{{ readClassAuditForm.Status | ClassAuditStatus }}</span>
          <span v-if="readClassAuditForm.Status===3" class="status-ed">{{ readClassAuditForm.Status | ClassAuditStatus }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>学员身份证号：</span><span>{{ readClassAuditForm.StudentIdCard }}</span>
        </el-col>
        <el-col :span="12">
          <span>家长身份：</span><span>{{ readClassAuditForm.ElderType | elderType }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>家长姓名：</span><span>{{ readClassAuditForm.ElderName }}</span>
        </el-col>
        <el-col :span="12">
          <span>家长手机号：</span><span>{{ readClassAuditForm.ElderPhone }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row" v-if="readClassAuditForm.Remarks">
        <el-col :span="12">
          <span>审核拒绝理由：</span><span>{{ readClassAuditForm.Remarks }}</span>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog :title="AuditTitle" v-if="AuditVisible" :visible.sync="AuditVisible" width="50%" :close-on-click-modal='false'>
      <el-form :model="AuditForm" :rules='rules' ref='AuditForm' label-width="auto">
        <el-form-item label="学员姓名:" prop="StudentName">
          <el-input v-model="AuditForm.StudentName" :readonly="repeatStatus || select" @blur="changeStudent()" show-word-limit maxlength="10" v-if="repeatStatus || select" class="readonly-input">
            <i slot="suffix" v-if='repeatStatus || select' @click="changeRepeatStatus(1)" class="el-input__icon el-icon-close"></i>
          </el-input>
          <el-input v-model="AuditForm.StudentName" :readonly="repeatStatus || select" @blur="changeStudent()" show-word-limit maxlength="10" v-else>
            <i slot="suffix" v-if='repeatStatus || select' @click="changeRepeatStatus(1)" class="el-input__icon el-icon-close"></i>
          </el-input>
        </el-form-item>
        <div class="table-box" v-if="repeatList.length > 0">
          <div class="text">重名信息学员列表</div>
          <el-table class="repeatList" :data="repeatList" border fit stripe>
            <el-table-column prop="Name" label="学员姓名（备注）" align="center" />
            <el-table-column label="性别" width="50" align="center">
              <template slot-scope="{ row }">
                {{ row.Sex === 1 ? '男' : '女' }}
              </template>
            </el-table-column>
            <el-table-column prop="Birthday" label="生日" align="center" />
            <el-table-column label="已绑定家长及手机号" align="center" :show-overflow-tooltip="true" min-width="100%">
              <template slot-scope="{ row }">
                <span v-for="(item,index) in row.Elders" :key='index'>
                  {{ item.Type !== 7 ? row.Name : '' }}{{ item.Type | elderType }}({{ item.Phone }})
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="MemberCount" label="所属班级" align="center" :show-overflow-tooltip="true">
              <template slot-scope="{ row }">
                {{ Array.isArray(row.Classes) && row.Classes.map(item=> item.Name).join('/') }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" v-if="!row.select" @click="selectStudent(row)" class="btn-box"> 选择学员 </el-button>
                <el-button type="text" v-else disabled class="btn-Selected"> 已选择 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="text text-color" @click="changeRepeatStatus()">以上都不是，新建重名备注</div>
        </div>
        <el-form-item label="重名备注:" prop="RepeatName" v-if="repeatStatus">
          <el-row>
            <el-col :span="8">
              <el-input v-model="Name" readonly disabled></el-input>
            </el-col>
            <el-col :span="8" :offset="2">
              <el-input v-model="repeatName" placeholder="请输入重名备注"></el-input>
            </el-col>
            <el-col :span="4" :offset="2">
              <el-button @click="addRepeatName()" class="discoloration"> 添加 </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <div v-if="repeatParent !== 0" class="repeatParent-box">
          <span v-if="repeatParent === 1" class="success">当前学员姓名+重名备注可用，俱乐部内无重复信息</span>
          <span v-else class="error">当前学员姓名+重名备注不可用，请重新输入重名备注</span>
        </div>
        <el-form-item label="学员性别:" prop="StudentSex">
          <el-radio-group v-model="AuditForm.StudentSex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学员生日:" prop="StudentBirthday">
          <el-row>
            <el-col :span="20">
              <el-date-picker class='select-box' :clearable='false' :picker-options="pickerOptions" v-model="AuditForm.StudentBirthday" placeholder="选择日期">
              </el-date-picker>
            </el-col>
            <el-col :span="3" :offset="1">
              <span>{{Age}}岁</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="学员身份证号:" prop="StudentIdCard">
          <el-input v-model="AuditForm.StudentIdCard" />
        </el-form-item>
        <el-form-item label="家长身份:" prop="ElderType">
          <el-select class="select-box" v-model="AuditForm.ElderType">
            <el-option v-for="item in parentTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="家长姓名:" prop="ElderName">
          <el-input v-model="AuditForm.ElderName" show-word-limit maxlength="10" />
        </el-form-item>
        <el-form-item label="手机号:" prop="ElderPhone">
          <el-input v-model="AuditForm.ElderPhone" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuseAudit()" class="plain-button">拒绝</el-button>
        <el-button @click="updateAudit()" class="discoloration">同意</el-button>
      </span>
    </el-dialog>
    <!-- 审核拒绝 -->
    <el-dialog title="审核拒绝" :visible.sync="refuseAuditVisible" width="40%" :close-on-click-modal='false'>
      <el-form>
        <el-form-item label="拒绝理由:" prop="Remarks">
          <el-input v-model="AuditForm.Remarks" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="拒绝理由" show-word-limit maxlength="200" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toAudit()" class="plain-button">返回</el-button>
        <el-button @click="updateRefuseAudit()" class="discoloration">提交</el-button>
      </span>
    </el-dialog>
    <!-- 分配教练 -->
    <el-dialog title="分配教练" :visible.sync="assignCoachVisible" width="40%" :close-on-click-modal='false'>
      <el-form>
        <el-form-item label="">
          <el-row v-for="(item,index) in selectTeacherList" :key='index'>
            <el-col :span="5" class="col-box">
              <el-select class="select-box" v-model="item.TeacherType" placeholder="请选择">
                <el-option v-for="item in TeacherTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" :offset="1" class="col-box">
              管理员:
            </el-col>
            <el-col :span="5" class="col-box">
              <el-select class="select-box" v-model="item.Manager" placeholder="请选择">
                <el-option v-for="item in ManagerList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7" :offset="1">
              <el-select class="select-box" v-model="item.Id" @change="changeTeacher(index)" placeholder="请选择教练">
                <el-option v-for="item in TeacherList" :key="item.Id" :label="item.Name" :value="item.Id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" :offset="1"><i class="el-icon-delete" @click="deleteTeacher(index)"></i></el-col>
          </el-row>
          <el-button icon="el-icon-plus" @click="addTeacher" class="addTeacher discoloration">添加教练</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="assignCoachVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="updateClass()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClassCheckList, batchClassApplyCheck, updateClubClass, classApplyCheck, getClubClassById, getAllClubTeacher, getClubClassList } from "@/service/organizational";
import { getClubStudentList } from '@/service/educational.js';
import pagination from '@/components/pagination/pagination.vue'; // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝
import { ClassAuditStatusList, parentTypeList, TeacherTypeList, ManagerList } from '@/config/data';
import moment from 'moment';

const ListQuery = { // 查询数据
  PageNo: 1,  // 页码
  PageSize: 50, // 分页条数
  StudentName: '',  // 学员姓名
  Status: undefined, // 学员状态状态
  ElderPhone: '', // 家长手机号码
};
const ClassAuditForm = {
  StudentName: '',
  ElderType: '',
  ElderPhone: '',
};
export default {
  name: "Examine",
  components: {
    pagination,
  },
  data() {
    const validateStudentName = (rule, value, callback) => {
      if (!this.select && this.repeatParent !== 1 && this.repeatList.length > 0) {
        return callback(new Error('存在重名学员，请确认学员身份'));
      }
      if (!value || value.trim() === '') {
        return callback(new Error('请输入学员姓名'));
      }
      callback();
    };

    const validateElderType = (rule, value, callback) => {
      if (this.ClassAuditForm.ElderType || this.AuditForm.ElderType) {
        return callback();
      }
      callback(new Error('请选择家长身份'));
    };

    const validateElderPhone = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入手机号'));
      } else {
        let p = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (p.test(value.trim())) {
          callback();
        }
        return callback(new Error('请输入正确手机号'))
      }
    };

    const validateRepeatName = (rule, value, callback) => {
      if (!this.repeatName || this.repeatName.trim() === '') {
        return callback(new Error('请输入重名学员家长名称'));
      }
      callback();
    };

    const validateElderName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入家长姓名'));
      }
      callback();
    };
    return {
      moment,
      ManagerList,
      TeacherTypeList, // 教师类型
      parentTypeList, // 家长身份
      ClassAuditStatusList, // 班级审核状态
      listQuery: deepClone(ListQuery),
      ClassAuditForm: deepClone(ClassAuditForm),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      multipleSelection: [],  // 多选数据
      ClassAuditVisible: false,  // 编辑状态
      ClassAuditTitle: '编辑',
      readVisible: false,  // 查看详情状态
      readClassAuditForm: '',
      AuditVisible: false, // 审核状态
      AuditForm: {
        StudentName: '',
        ElderType: '',
        ElderPhone: '',
        Remarks: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > (Date.now() - 3600 * 1000 * 24); // 
        },
      },
      AuditTitle: '审核',
      refuseAuditVisible: false, // 审核拒绝
      rules: {
        StudentName: [{ required: true, validator: validateStudentName, trigger: 'blur' }],
        ElderType: [{ required: true, validator: validateElderType, trigger: 'blur' }],
        ElderPhone: [{ required: true, validator: validateElderPhone, trigger: 'blur' }],
        ElderName: [{ required: true, validator: validateElderName, trigger: 'blur' }],
        RepeatName: [{ required: true, validator: validateRepeatName, trigger: 'blur' }],
      },
      repeatList: [],
      repeatName: '',  // 重名家长名称
      repeatStatus: false, // 是否点击重名
      repeatParent: 0, // 重名备注是否重复
      Name: '',   // 名字暂存
      select: false,
      Id: '',
      Teachers: [],
      assignCoachVisible: false,
      selectTeacherList: [{
        TeacherType: 1,
        Id: '',
        Manager: false
      }], // 添加教练列表
      TeacherList: [],  // 所有教练列表
      classDetailData: {},
    }
  },

  computed: {
    Age() {
      if (!this.AuditForm.StudentBirthday) {
        return 0;
      }
      // 年
      let age = new Date().getFullYear() - moment(this.AuditForm.StudentBirthday).format('YYYY');
      // 判断月
      if (new Date().getMonth() + 1 - moment(this.AuditForm.StudentBirthday).format('MM') > 0) {
        return age;
      }
      // 判断日
      if (new Date().getMonth() + 1 - moment(this.AuditForm.StudentBirthday).format('MM') == 0) {
        if (new Date().getDate() - moment(this.AuditForm.StudentBirthday).format('DD') > 0) {
          return age;
        }
      }
      return age - 1;

    }
  },

  created() {
    this.Id = this.$route.query.id;
    this.getClubClassById();
    this.getClassCheckList();
    this.getAllClubTeacher();
    this.getClubClassList();
  },
  methods: {
    /**
      * 获取班级审核列表数据
      */
    async getClassCheckList() {
      this.loading = true;
      let res = await getClassCheckList({ ...this.listQuery, ClassIds: [this.$route.query.id] });
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },


    /**
     * 获取班级列表数据
     */
    async getClubClassList() {
      this.loading = true;
      let res = await getClubClassList();
      this.tabList = res.DataList;
      this.loading = false;
    },

    /**
     * 获取详情数据
     */
    async getClubClassById() {
      this.loading = true;
      this.classDetailData = await getClubClassById({ Id: this.Id });
      this.Teachers = this.classDetailData.Teachers;
      this.loading = false;
    },

    /**
     * 获取所有教练
     */
    async getAllClubTeacher() {
      let res = await getAllClubTeacher()
      this.TeacherList = res
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.StudentName = this.listQuery.StudentName && this.listQuery.StudentName.trim();
      this.listQuery.ElderPhone = this.listQuery.ElderPhone && this.listQuery.ElderPhone.trim();
      this.listQuery.Status = this.listQuery.Status || undefined;
      this.getClassCheckList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClassCheckList();
    },

    /**
     * 页头-审核加入
     */
    async approveAudit() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 0) {
        this.$message.error('请勾选至少一条数据');
        return;
      }
      try {
        let res = await this.$confirm('是否确定学员和家长信息无误，批量审核通过?', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await batchClassApplyCheck({ Status: 1, Ids: this.multipleSelection.map(item => item.Id), ClassId: this.$route.query.id });
        this.getClassCheckList();
        this.$message({
          message: '批量审核成功',
          type: 'success'
        });
      } catch (err) { };
    },

    /**
     * 操作-查看详情
     */
    readDetail(row) {
      this.readVisible = true;
      this.readClassAuditForm = { ...row };
    },

    /**
     * 操作-审核
     */
    async changeAuditVisible(row) {
      this.repeatList = [];
      this.repeatName = '';
      this.repeatParent = 0;
      this.select = false;
      this.repeatStatus = false;
      this.AuditVisible = true;
      this.AuditForm = { ...row };
      this.AuditForm.StudentBirthday = this.AuditForm.StudentBirthday * 1000;
      this.changeStudent();
    },

    /**
     * 审核拒绝
     */
    refuseAudit() {
      this.refuseAuditVisible = true;
    },

    /**
     * 审核拒绝理由返回
     */
    toAudit() {
      this.refuseAuditVisible = false;
    },

    /**
     * 审核拒绝理由提交
     */
    async updateRefuseAudit() {
      await classApplyCheck({
        Remarks: this.AuditForm.Remarks,
        Id: this.AuditForm.Id,
        Status: 3
      });
      this.$message.success('拒绝成功');
      this.AuditVisible = false;
      this.refuseAuditVisible = false;
      this.getClassCheckList();
    },

    /**
     * 审核确定
     */
    async updateAudit() {
      await this.changeStudent();
      this.$refs.AuditForm.validate(async (valid) => {
        if (!valid) return;

        await classApplyCheck({
          StudentName: this.AuditForm.StudentName,
          ElderPhone: this.AuditForm.ElderPhone,
          ElderType: this.AuditForm.ElderType,
          ClassId: this.$route.query.id,
          ElderName: this.AuditForm.ElderName,
          StudentBirthday: new Date(this.AuditForm.StudentBirthday).getTime() / 1000,
          StudentIdCard: this.AuditForm.StudentIdCard,
          StudentSex: this.AuditForm.StudentSex,
          Id: this.AuditForm.Id,
          Status: 1
        });
        this.$message.success('审核成功');
        this.AuditVisible = false;
        this.getClassCheckList();
      })
    },

    /**
      * 主体-table表格多选框操作
      */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /**
     * 输入学员失焦
     */
    async changeStudent(edit) {
      if (!this.AuditForm.StudentName || this.AuditForm.StudentName.trim() === '' || this.repeatStatus) {
        return;
      }
      let res = await getClubStudentList({ Name: this.AuditForm.StudentName.trim() });
      this.repeatList = [];
      if (Array.isArray(res.DataList) && res.DataList.length > 0) {
        this.Name = this.AuditForm.StudentName;
        let arr = res.DataList.filter(item => {
          if (edit) {
            return item.Name.indexOf(`${this.AuditForm.StudentName.trim()}(`) !== -1;
          } else {
            return this.AuditForm.Id !== item.Id && item.Name === this.AuditForm.StudentName.trim() || item.Name.indexOf(`${this.AuditForm.StudentName.trim()}(`) !== -1;
          }
        })
        this.repeatList = arr.map(item => {
          return {
            ...item,
            select: false
          }
        });
      }
      this.$refs.AuditForm.validateField('StudentName');
    },

    /**
     * 点击以上都不是，添加备注
     */
    changeRepeatStatus(flag) {
      this.repeatList = this.repeatList.map(item => { // 点击后都改为可选
        return {
          ...item,
          select: false
        }
      });
      if (!this.repeatStatus) {
        this.AuditForm.StudentName = this.Name;
      }
      this.repeatStatus = true;
      if (flag) {
        this.repeatList = [];
        this.repeatName = '';
        this.AuditForm.StudentName = '';
        this.repeatParent = 0;
        this.repeatStatus = false;
        this.select = false;
        this.$refs.AuditForm.validateField('StudentName');
      }
    },

    /**
     * 添加备注
     */
    async addRepeatName() {
      if (!this.repeatName || this.repeatName.trim() === '') {
        this.$refs.AuditForm.validateField('RepeatName');
        return;
      }
      this.AuditForm.StudentName = this.Name + `(${this.repeatName})`;
      let res = await getClubStudentList({ Name: this.AuditForm.StudentName.trim() });
      let arr = res.DataList.filter(item => {
        return item.Name === this.AuditForm.StudentName.trim();
      });
      this.repeatParent = 1;
      if (arr.length > 0) {
        this.repeatParent = 2;
      }
      this.$refs.AuditForm.validateField('StudentName');
      this.$refs.AuditForm.validateField('RepeatName');
    },

    /**
     * 选择学员
     */
    selectStudent(row) {
      this.repeatList = this.repeatList.map(item => { // 选择后将除自己之外的都改为可选
        if (row.Id === item.Id) {
          return {
            ...item,
            select: true
          }
        } else {
          return {
            ...item,
            select: false
          }
        }
      });
      this.AuditForm.StudentName = row.Name;
      // this.AuditForm.StudentBirthday = row.Birthday;
      // this.AuditForm.StudentSex = row.Sex;
      // this.AuditForm.StudentIdCard = row.IdCard;
      this.select = true;
      this.repeatStatus = false;
      this.$refs.AuditForm.validateField('StudentName');
    },


    /**
     * 判断选择的是否一样
     */
    changeTeacher(flag) {
      let bool = false;
      this.selectTeacherList.forEach((item, index) => {
        if (flag !== index && this.selectTeacherList[flag].Id === item.Id) {
          this.selectTeacherList[flag].Id = '';
          bool = true;
        }
      });
      if (bool) {
        this.$message.error('当前教练已被选择，请重新选择');
      }
    },

    /**
     * 添加教练
     */
    addTeacher() {
      let obj = {
        TeacherType: 1,
        Id: '',
        Manager: false
      };
      this.selectTeacherList.push(obj);
    },

    /**
     * 删除教练
     */
    deleteTeacher(index) { //默认至少分配一个教练
      if (Array.isArray(this.selectTeacherList) && this.selectTeacherList.length === 1) {
        this.$message.error('至少分配一个教练')
        return
      }
      Array.isArray(this.selectTeacherList) && this.selectTeacherList.splice(index, 1);
    },

    /**
     * 点击分配教练
     */
    assignCoach() {
      this.assignCoachVisible = true;
    },

    /**
     * 确认分配教练
     */
    async updateClass() {
      if (Array.isArray(this.selectTeacherList) && this.selectTeacherList.filter(item => !item.Id).length > 0) {
        this.$message.error('请选择教练');
        return;
      }
      try {
        await updateClubClass({
          Id: this.classDetailData.Id,
          Teachers: [...this.selectTeacherList],
          AgeGroup: this.classDetailData.AgeGroup,
          Name: this.classDetailData.Name,
          Remarks: this.classDetailData.Remarks,
          Type: this.classDetailData.Type,
          StartDate: this.classDetailData.Type === 2 && this.classDetailData.StartDate ? new Date(this.classDetailData.StartDate).getTime() : 0,
          EndDate: this.classDetailData.Type === 2 && this.classDetailData.EndDate ? new Date(this.classDetailData.EndDate).getTime() : 0,
          SubOrgId: this.classDetailData.SubOrgId,
        })
        this.$message.success('分配教练成功');
        this.assignCoachVisible = false;
        this.getClubClassById();
        this.$emit('getDetail');
      } catch (err) { };
    },
  },
}
</script>
<style lang='scss' scoped>
/deep/.input-width {
  width: 216px !important;
  height: 32px;
}
.input-box {
  width: 216px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  text-align: center !important;
}

.table-box {
  padding: 10px 0;
  .text {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    padding: 5px 0;
  }
  .text-color {
    color: #8fcc80;
    font-weight: 400;
    cursor: pointer;
  }
  /deep/ .el-table {
    min-height: 0 !important;
  }
}
.repeatParent-box {
  display: flex;
  justify-content: center;
  .success {
    padding: 5px;
    border: 1px solid rgb(183, 235, 143);
    border-radius: 4px;
    background: rgb(183, 235, 143);
  }
  .error {
    padding: 5px;
    border: 1px solid rgb(255, 163, 158);
    border-radius: 4px;
    background: rgb(255, 163, 158);
  }
}
.el-icon-close {
  cursor: pointer;
}
.readonly-input {
  /deep/.el-input__inner {
    background: #fafafa;
  }
}
.assignCoach-div {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin: 20px 0;
  }
}
.addTeacher {
  display: block;
  width: 100px;
  margin: 20px auto;
}

.btn-Selected {
  font-weight: 400;
  color: #6a757e !important;
}

.page-main {
  overflow-x: hidden;
}
.scatter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 42px !important;
}
</style>